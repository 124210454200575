import React from 'react'
import '../style/input.css'

const Input = ({input}) => (
	<div className='input'>
		{input}
	</div>
);

export default Input;
