import './App.css';
import logo from './assets/logoCBC.png'
import Boton from './components/Boton';
import Input from './components/Input';
import Clear from './components/Clear';
import { useState } from 'react';
import { evaluate } from 'mathjs';

function App() {

  const[input, setInput] = useState('');

  const addInput = val => {
    setInput(input + val);
  };

  const calculator = () => {
    if (input) {
    setInput(evaluate(input));
    }
    else {
    alert ('error')  
    }
  };

  return (
    <div className="App">
      <div className='logo-container'>
        <img 
        src={logo}
        className='logo'
        alt='logo'
        />
      </div>
      <div className='calculator-container'>
        <Input input = {input} />
        <div className='row container'>
          <Boton manageClic={addInput}>7</Boton>
          <Boton manageClic={addInput}>8</Boton>
          <Boton manageClic={addInput}>9</Boton>
          <Boton manageClic={addInput}>*</Boton>
        </div>
        <div className='row container'>
          <Boton manageClic={addInput}>4</Boton>
          <Boton manageClic={addInput}>5</Boton>
          <Boton manageClic={addInput}>6</Boton>
          <Boton manageClic={addInput}>-</Boton>
        </div>
        <div className='row container'>
          <Boton manageClic={addInput}>1</Boton>
          <Boton manageClic={addInput}>2</Boton>
          <Boton manageClic={addInput}>3</Boton>
          <Boton manageClic={addInput}>+</Boton>
        </div>
        <div className='row container'>
          <Boton manageClic={calculator}>=</Boton>
          <Boton manageClic={addInput}>0</Boton>
          <Boton manageClic={addInput}>.</Boton>
          <Boton manageClic={addInput}>/</Boton>
        </div>
        <div className='row container'>
          <Clear manageClear={() => setInput('')}>Clear</Clear>
        </div>
      </div>
    </div>
  );
}

export default App;
