import React from 'react'
import '../style/clear.css'

const Clear = (props) => (
    <div className='button-clear'
    onClick={props.manageClear}>
        {props.children}
    </div>
);

export default Clear;
