import React from 'react'
import '../style/boton.css';

export default function Boton(props) {
  
	const isOperator = value => {
		return isNaN(value) && (value !== '.') & (value !== '=');
	};
	
	return (
    <div
			className={`container-button ${isOperator(props.children) ? 'operator' : null }`.trimEnd()}
			onClick={() => props.manageClic(props.children)}>
        {props.children}
    </div>
  )
}
